import { Container } from "react-bootstrap";
import NavBar from "./Navigation/NavBar";
import TransitionCurtain from "./TransitionCurtain";
import Events from '../Assets/flyer.png';

export default function EventsPage() {
    const handleExternalNavigation = () => {
        window.location.href = 'https://www.lejuantruly.com/lethal-premiere-rsvp';
      };

    return (
		<div>
		<NavBar />
		<TransitionCurtain title={"EVENTS"} />
        <Container 
                fluid 
                className="d-flex flex-column justify-content-center align-items-center" 
                style={{ 
                    zIndex: "10", 
                    overflow: "hidden", 
                    flex: 1,
                    padding: "20px 0"  // Add some padding top and bottom
                }}
            >
                <img 
                alt="event"
                    src={Events} 
                    style={{
                        maxHeight: "calc(100vh - 250px)",  // Adjust based on your navbar and padding
                        width: "auto",
                        objectFit: "contain",
                        marginTop: '100px'
                    }}
                />
                    <h5 className='navbar-text-rsvp' onClick={handleExternalNavigation} >
                    RSVP
                    </h5>
            </Container>
            </div>
    )
}