import { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { useShopify } from "../hooks";

import SizeVariant from "./SizeVariants";
import TransitionCurtain from "../Components/TransitionCurtain";
import NavBar from '../Components/Navigation/NavBar';
import Splat1 from '../Assets/splatRed@3xFinal.png';
import Splat2 from '../Assets/splatRed2@3xFinal.png';

export default function Products() {
	const {
		products,
		fetchProduct,
	} = useShopify()

	const id = "id"
	const prodLength = products && products.length

	useEffect(() => {
		fetchProduct(id)
	}, [id])

	return (
		<div>
		<NavBar />
		<TransitionCurtain title={"SHOP"} />
		<Container fluid style={{ zIndex: "10", overflow: "hidden", height: "100%" }}>
			<Row style={{ overflowY: "hidden", height: "100%" }}>
				{products &&
					products.map((product, i) => {
						const image = product.images[0]
						const description = product.description && product.description.split(".")
						const price = product.variants[0].price.amount
						return (
							<Col lg={{ span: prodLength === 1 ? 4 : 4, offset: prodLength === 1 ? 4 : `${i % 2 === 0 ? 1 : 2}` }} xs={{ span: 8, offset: 2 }} 
								key={product.id + i} style={{ marginTop: "20vh" }}
							>
								{/* image side */}
								<div className="prod-img-div" style={{ backgroundImage: `url(${image.src})`}} alt={`${product.title}`} > 
								</div>
								<img src={Splat1} className="splat1-prod" />
      							<img src={Splat2} className="splat2-prod" />
								{/* info side */}
								<div style={{ float: "right", width: "100%" }}>
									{/* title */}
									<div>
										<h3 className="prod-title" >{product.title}</h3>
									</div>
									<SizeVariant 
										product={product} 
										description={description}
										i={i}
										price={price}
									/>
								</div>
							</Col>
					)
				})}
			</Row>
		</Container>
		</div>
	)
}