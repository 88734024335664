import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useAnimation, motion } from "framer-motion";
import { AnimatePresence } from 'framer-motion';
import { useShopify } from './hooks';

import Home from './Components/Home';
import Cart from './Components_Shopify/Cart';
import Music from './Components/Music';
import Videos from './Components/Videos';
import NavBar from './Components/Navigation/NavBar';
import Loading from './Components/Lottie/Loading';
import Product from './Components_Shopify/Product';

import Finger1 from './Assets/FingerPrint_1.jpg';
import Finger2 from './Assets/FingerPrint_2.jpg';
import Finger3 from './Assets/FingerPrint_3.jpg';
import Finger4 from './Assets/FingerPrint_4.jpg';

import './Styles/App.css'
import Events from './Components/Events';

const App = () => {
  const location = useLocation();
  const [load, setLoad] = useState(false);

  const imgAnimation = useAnimation()

  const handleMouseMove = e => {
    const { clientX, clientY } = e
    const moveX = clientX - window.innerWidth / 2
    const moveY = clientY - window.innerHeight / 2
    const offsetFactor = 15
    imgAnimation.start({
      x: moveX / offsetFactor,
      y: moveY / offsetFactor
    })
  }

  const {
		createShop,
		createCheckout,
		fetchProducts,
	} = useShopify()

	useEffect(() => {
		createShop()
		fetchProducts()
		createCheckout()
	}, [])

  useEffect(() => {
    (async () => {
      if (!load) {
        return setTimeout(function() {
          setLoad(true)
        }, 3000)
      }
    })();
  },[])

  return (
    <div onMouseMove={e => handleMouseMove(e)}>
      {/* <NavBar load={load} /> */}
      <Cart/>
      {!load ?
        <Loading />
          :
        <AnimatePresence initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path='/' element={<Home/>} />
            <Route path='/music' element={<Music/>} />
            <Route path='/videos' element={<Videos/>} />
            <Route path='/shop' element={<Product/>} />
            <Route path='/events' element={<Events/>} />
          </Routes>
        </AnimatePresence>
      }
      <div class="container">
        <motion.img
          animate={imgAnimation}
          src={Finger1}
          alt="finger Print 1"
          style={{ 
            mixBlendMode: "lighten",
            opacity: "0.3",
            height: "40vh",
            position: 'absolute', 
            bottom: -100, 
            left: 200,
        }}
        />
        <motion.img
          animate={imgAnimation}
          src={Finger2}
          alt="finger Print 2"
          style={{ 
            mixBlendMode: "lighten",
            opacity: "0.3",
            height: "50vh",
            position: 'absolute', 
            top: -100, 
            left: -200,
        }}
        />
        <motion.img
          animate={imgAnimation}
          src={Finger3}
          alt="finger Print 3"
          style={{ 
            mixBlendMode: "lighten",
            opacity: "0.3",
            height: "30vh",
            position: 'absolute', 
            bottom: 50, 
            right: 0,
        }}
        />
        <motion.img
          animate={imgAnimation}
          src={Finger4}
          alt="finger Print 4"
          style={{ 
            mixBlendMode: "lighten",
            opacity: "0.3",
            height: "60vh",
            position: 'absolute', 
            top: 100, 
            right: -200,
        }}
        />
      </div>
    </div>
  )
}

export default App;